import request from "@/utils/request";

//手动支付
export function payOperation(data) {
    return request({
        url: '/canteen/account/execConsume',
        method: 'post',
        data
    });
}
