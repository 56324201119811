export default {
  data() {
    return {
      toolbar: {
        size: 'small',
        labelWidth: '80px',
        igrone: ['canteenType'],
        model: {
          name: undefined,
          depId: undefined,
          departmentId: [],
          telephone: undefined,
          beginDate: '',
          endDate: '',
          type: undefined,
          status: undefined,
          auditStatus: undefined,
          canteenType: Number(Number(sessionStorage.getItem('canteenType')) !== 999 ? sessionStorage.getItem('canteenType') : 1),
        },
        fields: this.getToolbarFields()
      }
    };
  },
  methods: {
    getToolbarFields() {
      return [
        {
          label: '姓名',
          prop: 'name',
          placeholder: '请输入要搜索的姓名',
          clearable: true
        },
        {
          label: '部门',
          //prop: 'depId',
          prop: 'departmentId',
          placeholder: '请选择要搜索的部门',
          type: 'cascader',
          clearable: true,
          collapseTags: true,
          filterable: true,
          props: {
            expandTrigger: 'hover',
            emitPath: false,
            multiple: true,
            label: 'name',
            value: 'id'
          },
          options: [],
          url: '/department/getList',
          method: 'post'
        },
        {
          label: '电话',
          prop: 'telephone',
          placeholder: '请输入要搜索的电话',
          clearable: true
        },
        {
          label: '预约日期',
          prop1: 'beginDate',
          prop2: 'endDate',
          type: 'daterange',
          valueFormat: 'yyyy-MM-dd',
          format: 'yyyy-MM-dd',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          pickerOptions: {
            firstDayOfWeek: 1
          }
        },
        {
          label: '类型',
          prop: 'type',
          placeholder: '请选择要搜索类型',
          type: 'select',
          clearable: true,
          options: [
            { label: '全部', value: undefined },
            { label: '早餐', value: 1 },
            { label: '中餐', value: 2 },
            { label: '晚餐', value: 3 }
          ]
        },
        {
          label: '状态',
          prop: 'status',
          placeholder: '请选择要搜索状态',
          type: 'select',
          clearable: true,
          options: [
            { label: '全部', value: undefined },
            { label: '已预约', value: 1 },
            { label: '已使用', value: 2 },
            { label: '已取消', value: 3 },
            { label: '已支付', value: 4 },
            { label: '未支付', value: 5 }
          ]
        },
        {
          label: '审批状态',
          prop: 'auditStatus',
          placeholder: '请选择要搜索审批状态',
          type: 'select',
          clearable: true,
          options: [
            { label: '全部', value: undefined },
            { label: '已预约', value: 0 },
            { label: '已使用', value: 2 },
            { label: '已取消', value: 3 },
            { label: '已支付', value: 4 }
          ]
        },
        {
          label: '食堂',
          prop: 'canteenType',
          placeholder: '请选择要搜索食堂',
          type: 'select',
          // clearable: true,
          options: [
            { label: '全部', value: undefined },
            { label: '一食堂', value: 1 },
            { label: '二食堂', value: 2 }
          ]
        }
      ];
    },
    onLoaded(){
      if (sessionStorage.getItem('canteenType')){
        if (Number(sessionStorage.getItem('canteenType')) === 1){
          this.toolbar.fields[7].options = [{ label: '一食堂', value: 1 }]
        }else if (Number(sessionStorage.getItem('canteenType')) === 2){
          this.toolbar.fields[7].options = [{ label: '二食堂', value: 2 }]
        }
      }
    },
  }
};
