import {payOperation} from "@/api/canteen/index";

export default {
    data() {
        return {
            payloading:{
                loading:false,
                url: '/canteen/account/execConsume',
                method: 'post'
            }
        };
    },
    methods: {
        onPay(row){
            this.$confirm('此操作将直接支付此订单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                //Entertain(4,"E"),   //招待
                const userId = sessionStorage.getItem('userId')
                const username = sessionStorage.getItem('loginName');
                const data = {code:(row.id+'E'), operateUserId:userId, operateUserName:username}
                payOperation(data).then(res=>{
                    // console.log(res)
                    if (res.code === 200){
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        });
                        this.$refs.page.onSearch()
                    }
                })
            }).catch(() => {});
        },
    }
};
